import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SitemarkIcon from './SitemarkIcon';
import ColorModeIconDropdown from '../shared-theme/ColorModeIconDropdown';
import Avatar from '@mui/material/Avatar';
import { useContext } from 'react';
import { SessionContext, AuthenticationContext } from '../components/AuthenticationProvider';

interface Session {
  user: {
    name: string | null;
    email: string;
    image: string | null;
  };
}

// Feature flag to control the visibility of the Sign-up button
const showSignUpButton = false; // Change to `true` to display the button

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);
  const session = useContext(SessionContext) as Session | null;
  const authentication = useContext(AuthenticationContext);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 'calc(var(--template-frame-height, 0px) + 28px)',
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <SitemarkIcon />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button variant="text" color="info" size="small">Learn</Button>
              <Button variant="text" color="info" size="small">Feedback</Button>
              <Button variant="text" color="info" size="small">Community</Button>
              <Button variant="text" color="info" size="small">Quizzes</Button>
              <Button variant="text" color="info" size="small" sx={{ minWidth: 0 }}>Resources</Button>
              <Button variant="text" color="info" size="small" sx={{ minWidth: 0 }}>Articles</Button>
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 1, alignItems: 'center' }}>
            {session ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Avatar 
                  src={session.user.image || undefined} 
                  alt={session.user.name || 'User'} 
                  sx={{ width: 40, height: 40 }} 
                />
                {authentication && (
                  <Button onClick={authentication.signOut} color="primary" variant="outlined">
                    Sign out
                  </Button>
                )}
              </Box>
            ) : (
              <>
                <Button component={Link} to="/sign-in" color="primary" variant="text" size="small">
                  Sign in
                </Button>
                {/* Conditionally render the Sign-up button based on `showSignUpButton` */}
                {showSignUpButton && (
                  <Button component={Link} to="/sign-up" color="primary" variant="contained" size="small">
                    Sign up
                  </Button>
                )}
              </>
            )}
            <ColorModeIconDropdown />
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1 }}>
            <ColorModeIconDropdown size="medium" />
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="top"
              open={open}
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: {
                  top: 'var(--template-frame-height, 0px)',
                },
              }}
            >
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <MenuItem>Learn</MenuItem>
                <MenuItem>Feedback</MenuItem>
                <MenuItem>Community</MenuItem>
                <MenuItem>Quizzes</MenuItem>
                <MenuItem>Resources</MenuItem>
                <MenuItem>Articles</MenuItem>
                <Divider sx={{ my: 3 }} />
                {session ? (
                  <MenuItem>
                    <Button onClick={authentication?.signOut} color="primary" variant="outlined" fullWidth>
                      Sign out
                    </Button>
                  </MenuItem>
                ) : (
                  <>
                    {/* Conditionally render the Sign-up button in the drawer based on `showSignUpButton` */}
                    {showSignUpButton && (
                      <MenuItem>
                        <Button component={Link} to="/sign-up" color="primary" variant="contained" fullWidth>
                          Sign up
                        </Button>
                      </MenuItem>
                    )}
                    <MenuItem>
                      <Button component={Link} to="/sign-in" color="primary" variant="outlined" fullWidth>
                        Sign in
                      </Button>
                    </MenuItem>
                  </>
                )}
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
